import BaseValidation from '@/forms/Validations/BaseValidation';
import { shouldShowOptIn } from '@/store';

export const names = {
  NewsletterOptin: 'NewsletterOptin',
}

export default (path: string = '') =>
  new BaseValidation(
    [
      {
        name: names.NewsletterOptin,
        rules: { 
          required: (val) => {
            if (shouldShowOptIn()) return val
            return true
          }
         },
        messages: {
          required: 'Please check the optin',
        },
        modelValue: false,
      },
    ],
    path,
  );
