import { shouldShowOptIn } from '@/store';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class NewsletterOptinMixin extends Vue {
    get shouldShowOptinCheckBox() {
        return shouldShowOptIn()
    }
}
